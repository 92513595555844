
import { defineComponent } from 'vue';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';
import { formatRegionUrl } from '$modules/shipping/utils/region-url';
import OSection from 'theme/components/organisms/o-section.vue';
import MBrandsCarouselHomePage from 'theme/components/molecules/m-brands-carousel-home-page.vue';
import { SfLink } from '@storefront-ui/vue';

export default defineComponent({
  name: 'HomeBrands',
  components: {
    OSection,
    MBrandsCarouselHomePage,
    SfLink
  },
  props: {
    brandsList: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    return {
      getBrandsList: props.brandsList,
      localizedRoute,
      formatRegionUrl
    }
  }
})
